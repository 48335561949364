import * as React from 'react';
import translation from 'intl';
import LanguageProvider from 'LanguageProvider';
import { graphql } from 'gatsby';
import ProductSafety from 'components/pages/productSafety';
import NewLayout from 'components/NewLayout';
import SEOGraphQL from 'components/SEOGraphQL';
import { PageSEOType } from 'components/types';
import { PREFIX_CANONICAL_URL } from 'components/constant';

const pageUrl = `${PREFIX_CANONICAL_URL}/safety-management-system`;

const IndexPage = () => {
  return (
    <LanguageProvider messages={translation}>
      <NewLayout headerStyle="transparent">
        <ProductSafety pageUrl={pageUrl} />
      </NewLayout>
    </LanguageProvider>
  );
};
export default IndexPage;

export const Head = ({
  data,
}: {
  data: {
    strapiProductSpecificPage: PageSEOType;
  };
}) => {
  return (
    <SEOGraphQL data={data.strapiProductSpecificPage} canonicalURL={pageUrl} />
  );
};
export const query = graphql`
  query {
    strapiProductSpecificPage(ONLY_FOR_DEV_pageId: { eq: "productSafety" }) {
      seo {
        preventIndexing
      }
      SEO {
        metaDescription
        metaTitle
        metaSocial {
          title
          description
          image {
            data {
              attributes {
                url
              }
            }
          }
        }
      }
    }
  }
`;
